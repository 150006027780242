header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

h2.hello {
  /* opacity: 0.75; */
  margin-bottom: 0rem;
}

h1.myname {
  line-height: 6.5rem;
}
/* =============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  font-weight: 700;
}

/* =============== HEADER SOCIALS ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header__socials a {
  font-size: 1.6rem;
  transition: all ease-out 1s;
}

/* =============== HEADER SOCIALS: HOVER ============== */
.header__socials a:hover {
  transform: scale(1.2);
  transition: all ease 0.2s;
}

.header__socials::after {
  content: "";
  width: 2px;
    height: 4rem;
  background: var(--color-primary);
}

/* =============== ME ============== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 21rem;
  height: 25rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 6.5rem;
  border-radius: 12rem 12rem 0 0;
  /* overflow: hidden; */
  overflow-y: visible;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
}

/* =============== ME: IMG ============== */

.me img{
  transform: scale(1.7);
}
.me img:after {
  content: "";
  width: 100px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,1));
}

/* =============== SCROLL DOWN ============== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1rem;
}
.scrolldownflex a {
  transition: all ease-out 1s;
  transform: rotate(90deg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.4rem;
}

.scrolldownflex a:hover{
  transform: scale(1.1);
  transform: rotate(90deg) scale(1.05);
  transition: all ease-out .2s;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    height: 94vh;
    /* added after tutorial */
    padding-top: 4rem;
  }

  .header__socials,
  .scroll__down, .scrolldownflex {
    display: none;
  }

  /* added after tutorial */
  .me {
    width: 94%;
    max-width: 18rem;
    margin-top: 4rem;
    justify-content: center;
  }
  h1.myname {
    line-height: 4.3rem;
  }

  /* added after tutorial */
  .cta {
    margin-top: 1.7rem;
    gap: 1rem;
  }
}
