.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 2px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background: #00000070;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option h5 {
  color: var(--color-light);
}

.contact__option span {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/* ================= FORM ================== */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.8rem 1.5rem 1rem 1.5rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
  font-size: 1rem;
  transition: all 0.2s ease-out;
}
textarea{
  line-height: 1.4rem;
}
input:focus,
textarea:focus {
  background: rgba(0, 0, 0, 0.6);
  border-color: var(--color-primary);
}
/* INput text area place holder*/
input::placeholder,
textarea::placeholder {
  color: var(--color-primary-variant);
}
.float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
}
.float-label label {
  color: var(--color-primary-variant);
  position: absolute;
  transform: translate(25px, 20px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  pointer-events: none;
}
.float-label:focus-within label {
  transform: translate(26px, 5px) scale(0.85);
}
.label-active {
  transform: translate(26px, 5px) scale(0.85)!important;
}

button.btn.btn-primary:disabled {
  cursor: not-allowed;
  opacity: 0.8;
  background-color: var(--color-bg);
  color: var(--color-primary);
  border-color:var(--color-primary);
  pointer-events: none;
  transition: ease all .3s;
}
.btn-transition {
  transition: all 0.2s ease-out;
}
/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
